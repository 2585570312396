























import Vue from 'vue'
export default Vue.extend({
  name: 'SellEmailLists',
  data() {
    return {
      tab: null,
    }
  },
})
